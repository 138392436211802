var LockedSelection = (function() {
	'use strict';

	function LockedSelection($el) {
		// enforces new
		if (!(this instanceof LockedSelection)) {
			return new LockedSelection($el);
		}
		this.$ = $el;
		
		this.init();
		this.bindEvents();
	}

	LockedSelection.prototype.init = function() {
		this.isOpen = false;
		this.arrow = this.$.find('.locked-selection-arrow').first();
	};

	LockedSelection.prototype.bindEvents = function() {
		var self = this;
		this.$.click(function(event) {
			event.preventDefault();
			self.toggle();
		});
	};

	LockedSelection.prototype.open = function() {
		this.arrow.addClass('up').removeClass('down');
		this.$.addClass('open');
		this.isOpen = true;
	};

	LockedSelection.prototype.close = function() {
		this.arrow.addClass('down').removeClass('up');
		this.$.removeClass('open');
		this.isOpen = false;
	};

	LockedSelection.prototype.toggle = function() {
		this.isOpen ? this.close() : this.open();
	};

	LockedSelection.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return LockedSelection;
}());