var BackgroundPub = (function() {
	'use strict';

	function BackgroundPub($el) {
		// enforces new
		if (!(this instanceof BackgroundPub)) {
			return new BackgroundPub($el);
		}
		this.$ = $el;
		this.init();
	}

	BackgroundPub.prototype.init = function() {
		$('#main-wrapper-main-content').addClass('with-background-pub');

		this.imageUrl = this.$.data('image-url');
		this.backgroundColor = this.$.data('background-color');

		this.innerWrapper = this.$.find('.background-pub-inner-wrapper').first();
		

		this.innerWrapper.css('background-image', 'url("'+this.imageUrl+'")');
		this.$.css('background-color', this.backgroundColor);
	};

	BackgroundPub.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return BackgroundPub;
}());