var Purchase = (function() {
	'use strict';

	function Purchase($el) {
		// enforces new
		if (!(this instanceof Purchase)) {
			return new Purchase($el);
		}
		this.$ = $el;

		this.isOpen = false;

		this.init();
		this.bindEvents();
	}

	Purchase.prototype.init = function() {
		this.buttonSelector = ".purchase-label, .purchase-open-button";
		this.button = this.$.find(this.buttonSelector);
	};

	Purchase.prototype.bindEvents = function() {
		var self = this;

		this.$.on('click', this.buttonSelector, function (event) {
			event.preventDefault();

			self.toggle();
			self.button.blur();
		});
	};

	Purchase.prototype.open = function() {
		this.$.addClass('open');
		this.isOpen = true;
	};

	Purchase.prototype.close = function() {
		this.$.removeClass('open');
		this.isOpen = false;
	};

	Purchase.prototype.toggle = function() {
		this.isOpen ? this.close() : this.open();
	};

	Purchase.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return Purchase;
}());