var Config = (function() {
	'use strict';

	function Config() {
		// enforces new
		if (!(this instanceof Config)) {
			return new Config();
		}
		// constructor body
	}

	Config.css = {
		sectionListing : {
			paddingBottom : function () {return 60;}
		}
	};

	return Config;
}());