var LandingPage = (function() {
	'use strict';

	function LandingPage($el) {
		// enforces new
		if (!(this instanceof LandingPage)) {
			return new LandingPage($el);
		}

		if (!LandingPage.linkedElement.mainHeader) {
			LandingPage.linkedElement.mainHeader = $('.main-header').first();
		}

		this.$ = $el;

		this.init();
	}

	LandingPage.prototype.init = function() {
		// this.setContentHeight($(window).height());

		// $(".main-footer").addClass('on-landing-page');
		$('body').addClass('on-landing-page');
	};

	LandingPage.prototype.onResize = function(resize) {
		if (resize.height) {
			// this.setContentHeight(resize.size.height);
		}
	};

	LandingPage.prototype.setContentHeight = function(height) {
		// this.$.height(height-LandingPage.getHeaderHeight());
	};

	LandingPage.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	LandingPage.getHeaderHeight = function() {
		return LandingPage.linkedElement.mainHeader.height();
	};

	LandingPage.linkedElement = {
		mainHeader : null
	};

	return LandingPage;
}());