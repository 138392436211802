var ReadingInformation = (function() {
	'use strict';

	function ReadingInformation($el) {
		// enforces new
		if (!(this instanceof ReadingInformation)) {
			return new ReadingInformation($el);
		}

		this.$ = $el;
		
		this.init();
	}

	ReadingInformation.prototype.init = function() {
		this.$.find('.reading-information-line').each(function(index, el) {
			new ReadingInformationLine($(el));
		});
	};

	ReadingInformation.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return ReadingInformation;
}());

var ReadingInformationLine = (function() {
	'use strict';

	function ReadingInformationLine($el) {
		// enforces new
		if (!(this instanceof ReadingInformationLine)) {
			return new ReadingInformationLine($el);
		}

		this.$ = $el;
		this.init();
	}

	ReadingInformationLine.prototype.init = function() {
		this.color = this.$.data('color');
		this.legend = this.$.find('.reading-information-content-type-color-legend');

		this.setLegendColor(this.color);
	};

	ReadingInformationLine.prototype.setLegendColor = function(color) {
		this.legend.css('background-color', '#'+color);
	};

	return ReadingInformationLine;
}());