var WSFormListCreation = (function() {
	'use strict';

	function WSFormListCreation($el) {
		// enforces new
		if (!(this instanceof WSFormListCreation)) {
			return new WSFormListCreation($el);
		}
		this.$ = $el;
		this.tempList = [];
		this.submitIsEnabled = false;
		this.submitButton = this.$.find('input[type="submit"]');

		this.init();
	}

	WSFormListCreation.prototype.enableSubmit = function() {
		this.submitIsEnabled = true;
		this.submitButton.addClass('ws-bg-rose');
	};

	WSFormListCreation.prototype.disableSubmit = function() {
		this.submitIsEnabled = false;
		this.submitButton.removeClass('ws-bg-rose');
	};

	WSFormListCreation.prototype.init = function() {
		var self = this;
		this.checkSubmitAvailability();

		this.$.find('[data-serie-id]').each(function (i,e) {
			var serieId = $(e).data('serie-id');
			if($.inArray(serieId, self.tempList) === -1) {
				self.tempList.push(serieId);
			}
		});

		this.searchBar = SearchBar.createComponent(this.$.find('.search-bar').first());
		this.$.on('click', '.search-bar-result', {'component':this}, function(evt){
			evt.data.component.addToTempList(this);
			evt.data.component.searchBar.closeResultPlaceholder();
			evt.data.component.searchBar.emptySearch();
		});

		this.$.on('click', '[data-remove-from-list]', {component:this}, function(evt){
			evt.preventDefault();
			evt.stopPropagation();
			evt.data.component.removeFromTempList(this);
			return false;
		});

		this.$.submit(function(evt){
			if (self.submitIsEnabled) {
				var nom = $.trim(this.$.find('[name="nom"]').val());
				if(this.tempList.length == 0 || nom.length == 0){
					alert('Donnez un nom à votre liste et choisissez au moins une série');
					evt.preventDefault();
					return false;
				}
				var serieIDs = this.tempList.join('::');
				this.$.find('[name="serieIDs"]').val(serieIDs);
				if(typeof window.parent.fancybox != 'undefined'){
					window.parent.fancybox.showLoading();
				}
				return true;
			}
			else{
				alert('Vos devez indiquer au minimum deux séries pour créer une liste');
				evt.preventDefault();
				return false;
			}
		}.bind(this));
	};

	WSFormListCreation.prototype.removeFromTempList = function(elem){
		var serieId = $(elem).data('serie-id');
		if($.inArray(serieId, this.tempList) != -1) {
			var newTempList = [];
			for(var i = 0; i < this.tempList.length ; i++) {
				if(this.tempList[i] != serieId){
					newTempList.push(this.tempList[i]);
				}
			}
			this.tempList = newTempList;
			var slideIndex = $('#slider-create-liste .block-bd[data-serie-id="'+serieId+'"]').index();
			$('#slider-create-liste').slick('slickRemove', slideIndex);
			if(this.tempList.length == 0){
				$('[data-target-identifier="slider-create-liste"]').addClass('hidden');
			}
		}
		
		this.checkSubmitAvailability();
	};

	WSFormListCreation.prototype.addToTempList = function(elem){
		var serieId = $(elem).data('serie-id');
		if($.inArray(serieId, this.tempList) == -1) {
			var apercu = $(elem).find('.serie-apercu').html();
			this.tempList.push(serieId);
			$('#slider-create-liste').slick('slickAdd', apercu);
			WsPortailManager.replaceBdiImg();
			WsPortailManager.initStarNotation();
			if($('[data-modal-wrapper="1"]').length > 0){
				var modalWrapper = $('[data-modal-wrapper="1"]').first();
				var parent_url = decodeURIComponent(document.location.hash.replace(/^#/, ''));
				var height = modalWrapper.innerHeight();
				var width = modalWrapper.innerWidth();
				var params = '{"method":"fancyboxUpdate", "height":"' + height + '", "width":"' + width + '"}';
				$.postMessage(params, parent_url, parent);
			}
		}
		$('[data-target-identifier="slider-create-liste"]').removeClass('hidden');

		this.checkSubmitAvailability();
	};

	WSFormListCreation.prototype.checkSubmitAvailability = function() {
		this.tempList.length >= 2 ? this.enableSubmit() : this.disableSubmit();
	};

	WSFormListCreation.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return WSFormListCreation;
}());