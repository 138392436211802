var SearchBar = (function() {
	'use strict';

	function SearchBar($el) {
		// enforces new
		if (!(this instanceof SearchBar)) {
			return new SearchBar($el);
		}
		this.$ = $el;

		this.init();
		this.bindEvents();

		this.resultList = [];
		this.callback = {};
		this.callback.afterRenderResultList = null;

		window.pool.componentNeedingTimeUpdate.push(this);
	}

	SearchBar.config = {
		timeBetweenSearch : 500
	};

	SearchBar.prototype.init = function() {
		if (this.$.attr('id')) {
			window.appSearchBarList = window.appSearchBarList || {};
			window.appSearchBarList[this.$.attr('id')] = this;
		};
		this.timeSinceLastSearch = 0;
		this.needSearchOnTimeUpdate = false;

		this.input = this.$.find('input').first();
		this.searchUrl = this.$.data('search-url');
		this.resultPlaceholder = document.getElementById(this.$.data('result-placeholder'));
		this.$resultPlaceholder = $(this.resultPlaceholder);
	};

	SearchBar.prototype.bindEvents = function() {
		var self = this;

		var searchDebounce = $.debounce(250, function(evt){
			this.searchEvent();
		}.bind(this));

		this.input.on('input', searchDebounce);

		$(document).mouseup(function (e)
		{
			var container = this.$;
			var placeholderContainer = this.$resultPlaceholder;
			if (!this.$.data('keep-result-placeholder-opened')
				&& !container.is(e.target) // if the target of the click isn't the container...
				&& container.has(e.target).length === 0 // ... nor a descendant of the container
				&& !placeholderContainer.is(e.target) // nor the result placeholder
				&& placeholderContainer.has(e.target).length === 0 // nor a descendant of the result placeholder
			)
			{
				this.closeResultPlaceholder();
			}
		}.bind(this));
	};

	SearchBar.prototype.getSearchedValue = function() {
		return this.input.val();
	};

	SearchBar.prototype.emptySearch = function(){
		this.input.val('');
	};

	SearchBar.prototype.closeResultPlaceholder = function(){
		$(this.resultPlaceholder).addClass('hidden');
		$(this.resultPlaceholder).html('');
	};

	SearchBar.prototype.searchEvent = function() {
		this.search(this.getSearchedValue());
	};

	SearchBar.prototype.search = function(searchedValue) {
		var self = this;
		$(self.resultPlaceholder).addClass('hidden');
		if(searchedValue.length != 0) {
			$.ajax({
				url: self.getFinalSearchUrl(searchedValue)
			})
			.done(function (data) {
				self.displaySearchResult({
					search: searchedValue,
					result: data.result
				});
			});
		}
	};

	SearchBar.prototype.getFinalSearchUrl = function(searchedValue) {
		return this.searchUrl+'/'+searchedValue;
	};

	SearchBar.prototype.displaySearchResult = function(params) {
		var self = this;
		this.resultList = [];

		$.each(params.result, function(index, res) {
			self.resultList.push(res);
		});

		this.renderResultList();
	};

	SearchBar.prototype.renderResultList = function() {
		var $resPlaceholder = $(this.resultPlaceholder);
		$resPlaceholder.html('');

		$.each(this.resultList, function(index, res){
			$resPlaceholder.append(res);
		});
		$resPlaceholder.removeClass('hidden');

		if (typeof this.callback.afterRenderResultList === 'function') {
			this.callback.afterRenderResultList(this);
		}

		this.resultList = [];
	};

	SearchBar.prototype.onTimeUpdate = function(time) {
		this.timeSinceLastSearch += time.delta;
		if (this.needSearchOnTimeUpdate) {
			this.searchEvent();
		};
	};

	SearchBar.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return SearchBar;
}());