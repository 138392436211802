var SectionSubMenu = (function() {
	'use strict';

	function SectionSubMenu($el) {
		// enforces new
		if (!(this instanceof SectionSubMenu)) {
			return new SectionSubMenu($el);
		}
		
		this.$ = $el;

		this.parentContent = this.$.parents('.section-inner-wrapper').first();

		this.init();
	}

	SectionSubMenu.prototype.init = function() {
		this.updatePageContentHeight();
	};

	SectionSubMenu.prototype.onResize = function(resize) {
		if (resize.width) {
			this.updatePageContentHeight();
		}
	};

	SectionSubMenu.prototype.updatePageContentHeight = function(params) {
		var params = typeof params === 'object' ? params : {repeat : false};

		var thisHeight = this.getHeight(), parentHeight = this.parentContentHeight();

		if(thisHeight !== parentHeight){
			this.parentContent.css('min-height', thisHeight+'px');
		}

		if(params.repeat === true){
			this.updatePageContentHeight();
		}
	};

	SectionSubMenu.prototype.getHeight = function() {
		return this.$.height();
	};

	SectionSubMenu.prototype.parentContentHeight = function() {
		return this.parentContent.height();
	};

	SectionSubMenu.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return SectionSubMenu;
}());

