var BubbleModalWrapper = (function() {
	'use strict';

	var BubbleModalWrapperPoolOpened = null;

	function BubbleModalWrapper($el) {
		// enforces new
		if (!(this instanceof BubbleModalWrapper)) {
			return new BubbleModalWrapper($el);
		}

		this.$ = $el;

		this.isOpen = false;
		this.positionAbsolute = false;
		this.globalCloser = false;

		this.init();
		this.bindEvents();
	}

	BubbleModalWrapper.prototype.init = function() {
		this.$.data('component', this);
		this.button = this.$.find('a').first();
		this.bubble = this.$.find('.bubble-modal').first();
		this.bubbleInnerWrapper = this.bubble.find('.bubble-modal-inner-wrapper').first();
		this.positionAbsolute = this.bubble.hasClass('position-absolute');

		if (this.positionAbsolute) {
			this.absolutePositionnedWrapper = $('<div>', {class: 'bubble-modal-absolute-positionned-wrapper'});
			this.bubbleClone = this.bubble.clone();
			this.bubbleClone.appendTo(this.absolutePositionnedWrapper);
			$('#main-wrapper').append(this.absolutePositionnedWrapper);
		}

		this.bubbleModalDeco = this.getDisplayedBubble().find('.bubble-modal-deco').first();
		
		this.bubbleModalDecoStartPosition = {
			top : parseInt(this.bubbleModalDeco.css('top'), 10),
			left : parseInt(this.bubbleModalDeco.css('left'), 10)
		};
	};

	BubbleModalWrapper.prototype.bindEvents = function() {
		var self = this;

		this.button.click(function(event) {
			event.preventDefault();

			self.toggle();
		});

		this.$.find('li a').click(function () {
			self.close();
		})
	};

	BubbleModalWrapper.prototype.unbindEvents = function() {
		var self = this;
		this.button.off();
		this.$.find('li a').off();
	};

	BubbleModalWrapper.prototype.setBubblePosition = function () {
		this.setBubblePositionWith();
	};

	BubbleModalWrapper.prototype.setBubblePositionWith = function(position, width) {
		var windowWidth = $(window).width();

		if (typeof position !== 'object' || typeof width !== 'number') {
			this.bubble.addClass('showed');
			var position = typeof position === 'object' ? position : this.bubble.offset();
			var width = typeof width === 'number' ? width : this.bubbleInnerWrapper.width();
			this.bubble.removeClass('showed');
		}

		var rightEdgePositionX = position.left+width+5;
		var diff = rightEdgePositionX - windowWidth;
		
		this.getDisplayedBubble().css('right', (diff > 0 ? diff : 0)+'px');
		this.bubbleModalDeco.css('left', (this.bubbleModalDecoStartPosition.left+(diff > 0 ? diff : 0))+'px');
	};

	BubbleModalWrapper.prototype.open = function() {
		if (BubbleModalWrapperPoolOpened) {
			BubbleModalWrapperPoolOpened.close();
		}

		BubbleModalWrapperPoolOpened = this;

		this.bubble.addClass('showed');
		var position = this.bubble.offset();
		var width = this.bubbleInnerWrapper.width();
		this.bubble.removeClass('showed');

		if (this.positionAbsolute) {
			this.absolutePositionnedWrapper.offset(position);
		}

		this.setBubblePositionWith(position, width);

		if(!this.globalCloser){
			this.initGlobalCloser();
		}
		this.globalCloser.removeClass('bmgc-hidden');

		this.getDisplayedBubble().addClass('showed');

		this.isOpen = true;
	};

	BubbleModalWrapper.prototype.getDisplayedBubble = function() {
		return this.positionAbsolute ? this.bubbleClone : this.bubble;
	};

	BubbleModalWrapper.prototype.close = function() {
		this.getDisplayedBubble().removeClass('showed').css('right', 0);
		this.bubbleModalDeco.css('left', this.bubbleModalDecoStartPosition.left+'px'); 
		this.button.blur();
		if(!this.globalCloser){
			this.initGlobalCloser();
		}
		this.globalCloser.addClass('bmgc-hidden');
		this.isOpen = false;
	};

	BubbleModalWrapper.prototype.toggle = function() {
		this.isOpen ? this.close() : this.open();
	};

	BubbleModalWrapper.prototype.initGlobalCloser = function(){
		var globalCloser = $('#bubble-modal-global-closer');
		if(globalCloser.length == 0){
			$('body').append('<div id="bubble-modal-global-closer" class="bmgc-hidden"></div>');
			globalCloser = $('#bubble-modal-global-closer');
			this.globalCloser = globalCloser;
		}else{
			this.globalCloser = globalCloser;
		}
		this.globalCloser.unbind('click');
		var self = this;
		this.globalCloser.bind('click', function(evt){
			if (BubbleModalWrapperPoolOpened) {
				BubbleModalWrapperPoolOpened.close();
			}

			BubbleModalWrapperPoolOpened = false;
		});
	};

	BubbleModalWrapper.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	BubbleModalWrapper.retrieveAllComponentsIn = function retrieveAllComponentsIn (container) {
		var componentList = [];

		container.find('.bubble-modal-wrapper').each(function(index, el) {
			var component = $(el).data('component');

			if (component) {
				componentList.push(component);
			}
		});

		return componentList;
	};

	return BubbleModalWrapper;
}());