var componentFactory = {

blockComment: function (comment) {
	var user = comment.user;

	function addLeadingZero(value) {
		value += '';
		return (value.length === 1 ? '0' : '')+value;
	}

	var publishedDate = (function (date) {
		return [
			addLeadingZero(date.getDate()),
			addLeadingZero((date.getMonth()+1)),
			addLeadingZero(date.getFullYear()).substring(2)
		].join('/');
	})(new Date(parseInt(comment.published*1000, 10)));

	return [

	'<div class="block-action undefined mediao block-comment">',
		'<div class="block-action-portrait mediao__figure">',
			'<div class="block-action-portrait-inner-wrapper"><img src="'+user.avatar+'" alt=""/>',
				'<div class="block-action-portrait-cache"></div>',
			'</div>',
		'</div>',
		'<div class="block-action-description mediao__body right-pad-0">',
			'<div class="block-action-user-name-wrapper"><a href="#" class="block-action-user-name">'+user.pseudo+'</a>',
				'<div class="block-action-user-action-date">, Le '+publishedDate+'</div>',
			'</div>',
			'<div class="block-comment-content">',
				'<p>'+comment.text+'</p>',
			'</div>',
		'</div>',
	'</div>'

	].join('');
}
};