var TopBarInformation = (function() {
	'use strict';

	function TopBarInformation($el) {
		// enforces new
		if (!(this instanceof TopBarInformation)) {
			return new TopBarInformation($el);
		}
		this.$ = $el;

		this.init();
	}

	TopBarInformation.prototype.init = function() {
		this.bindEvents();
	};

	TopBarInformation.prototype.bindEvents = function() {
		var self = this;
		this.$.click(function(event) {
			event.preventDefault();
			self.close();
		});
	};

	TopBarInformation.prototype.close = function() {
		this.$.addClass('hidden');
	};

	TopBarInformation.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return TopBarInformation;
}());