var WSListUpdate = (function() {
	'use strict';

	function WSListUpdate($el) {
		// enforces new
		if (!(this instanceof WSListUpdate)) {
			return new WSListUpdate($el);
		}
		this.$ = $el;

		this.init();
	}

	WSListUpdate.prototype.init = function() {
		this.searchBar = SearchBar.createComponent(this.$.find('.search-bar').first());
		this.idListe = this.$.data('liste');
		this.addUrl = this.$.data('addUrl');
		this.$.on('click', '.search-bar-result', {'component':this}, function(evt){
			evt.data.component.addToList(this);
			evt.data.component.searchBar.closeResultPlaceholder();
			evt.data.component.searchBar.emptySearch();
		});

	};

	WSListUpdate.prototype.addToList = function(elem){
		var serieID = $(elem).data('serieId');
		var url = this.getFinalAddUrl(serieID);
		$.get(url, function(data){
			if(data.statut == 1){
				$('#ws-list-items .grid-block').unwrap();
				var html = '<div class="grid-block">' + data.content + '</div>';
				$('#ws-list-items').prepend(html);
				var length = $('#ws-list-items .grid-block').length;
				var rows = Math.ceil(length/4);
				for(var i = 0; i<=rows; i++){
					$('#ws-list-items > .grid-block:lt(4)').wrapAll('<div class="grid-row"></div>');
				}
				WsPortailManager.replaceBdiImg();
				WsPortailManager.initStarNotation();
			}else{
				alert('Une erreur est survenue');
			}
		});
	};

	WSListUpdate.prototype.getFinalAddUrl = function(serieID)
	{
		return this.addUrl + '/' + serieID;
	};

	WSListUpdate.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return WSListUpdate;
}());