var Slider = (function() {
	'use strict';

	function Slider($el) {
		// enforces new
		if (!(this instanceof Slider)) {
			return new Slider($el);
		}
		this.$ = $el;

		this.init();

		this.bindEvents();
	}

	Slider.prototype.init = function() {
		this.identifier = this.$.attr('id');
		this.nav = $('.slider-nav[data-target-identifier="'+this.identifier+'"]');
		var slidesToShow = 1;
		if(this.$.data('slidesToShow') != null){
			slidesToShow = this.$.data('slidesToShow');
		}
		this.$.slick({
			arrows : false,
			slidesToShow: slidesToShow
		});
	};

	Slider.prototype.bindEvents = function() {
		var self = this;
		this.nav.find('a.next').click(function(event) {
			event.preventDefault();
			$(this).blur();
			self.next();
		});

		this.nav.find('a.previous').click(function(event) {
			event.preventDefault();
			$(this).blur();
			self.previous();
		});
	};

	Slider.prototype.next = function() {
		this.changeSlide();
		this.$.slick('slickNext');
	};

	Slider.prototype.previous = function() {
		this.changeSlide();
		this.$.slick('slickPrev');
	};

	Slider.prototype.changeSlide = function() {
		$.each(BubbleModalWrapper.retrieveAllComponentsIn(this.$), function (i, bubbleWrapper) {
			bubbleWrapper.close();
		});
	};

	Slider.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return Slider;
}());