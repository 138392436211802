var MainMenuSearchBar = (function() {
	'use strict';

	function MainMenuSearchBar($el) {
		// enforces new
		if (!(this instanceof MainMenuSearchBar)) {
			return new MainMenuSearchBar($el);
		}

		this.$ = $el;

		this.init();
	}

	MainMenuSearchBar.prototype.init = function() {
		var self = this;
		this.searchBar = SearchBar.createComponent(this.$.find('.search-bar').first());

		this.bubbleModalWrapperList = [];
		self.modalOpenerList = [];
		
		this.searchBar.callback.afterRenderResultList = function () {
			window.WsPortailManager.replaceBdiImg();
			window.WsPortailManager.initStarNotation();

			$.each(self.bubbleModalWrapperList, function (i, e) {
				e.unbindEvents();
			});

			$.each(self.modalOpenerList, function (i, e) {
				e.unbindEvents();
			});

			self.bubbleModalWrapperList = [];
			/*modal-opener*/
			self.searchBar.$resultPlaceholder.find('.bubble-modal-wrapper').each(function(index, el) {
				self.bubbleModalWrapperList.push(BubbleModalWrapper.createComponent($(el)));
			});

			self.modalOpenerList = [];
			self.searchBar.$resultPlaceholder.find('.modal-opener').each(function(index, el) {
				self.modalOpenerList.push(ModalOpener.createComponent($(el)));
			});
		};
	};

	MainMenuSearchBar.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return MainMenuSearchBar;
}());