var ActivityFeed = (function() {
	'use strict';

	function ActivityFeed($el) {
		// enforces new
		if (!(this instanceof ActivityFeed)) {
			return new ActivityFeed($el);
		}
		this.$ = $el;

		this.menuElementList = this.$.find('.activity-feed-menu-element');
		this.actionListing = this.$.find('.activity-feed-action-listing');
		this.contentContainer = this.$.find('.activity-feed-menu-content').first();
		this.infiniteScroll = false;

		this.init();
	}

	ActivityFeed.prototype.init = function() {
		var self = this;

		this.actions = [];
		this.initActionList();
		this.initInfiniteScroll();

		this.menuElementList.click(function (evt) {
			evt.preventDefault();
			self.clickOnMenuElementEvent($(this));
		});
	};

	ActivityFeed.prototype.initActionList = function() {
		var self = this;

		for(var i = 0, imax = this.actions.length;i<imax;i++){
			this.actions[i].unbindEvents();
		}

		this.actions = [];

		this.$.find('.activity-feed-action').each(function(index, el) {
			self.actions.push(new ActivityFeedAction($(el)));
		});
	};

	ActivityFeed.prototype.initInfiniteScroll = function(){
		var self = this;
		if (this.infiniteScroll != false) {
			this.infiniteScroll.jscroll.destroy();
			this.infiniteScroll = false;
		}

		this.infiniteScroll = this.$.find('.activity-feed-action-listing').jscroll({
			nextSelector: 'a.activity-feed-action-listing-next-page:last',
			contentSelector: '.activity-feed-action',
			padding: 20,
			loadingHtml: '<div class="activity-feed-loading"><span></span> Chargement...</div>',
			callback: function () {
				self.initActionList();
				var frameWrapper = $('[data-frame-wrapper]');
				if (frameWrapper.length > 0) {
					sendResizeFrame(frameWrapper.first());
				}
			}
		});
	};

	ActivityFeed.prototype.clickOnMenuElementEvent = function(target) {
		if (!target.hasClass('selected')) {
			this.loadContent(target.data('content-route'), target.data('content-route-parameters'));
		}

		this.menuElementList.removeClass('selected');
		target.addClass('selected');
	};

	ActivityFeed.prototype.loadContent = function(route, routeParameters) {
		var self = this;
		var contentContainer = this.contentContainer;

		contentContainer.addClass('loading-in-progress');
		
		window.reloadableBlockManager
			.load(route, routeParameters || {})
			.toTargetBlock(contentContainer).then(function () {
				contentContainer.removeClass('loading-in-progress');
				self.initActionList();
				self.initInfiniteScroll();
			});
	};

	ActivityFeed.prototype.toggle = function() {
		this.$.toggleClass('open');
		if(this.$.hasClass('open')) {
			var self = this;

			window.setTimeout(function () {
				self.initInfiniteScroll();
			}, 500);
		}
	};

	ActivityFeed.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return ActivityFeed;
}());