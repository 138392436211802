var SelectList = (function() {
	'use strict';

	function SelectList($el) {
		// enforces new
		if (!(this instanceof SelectList)) {
			return new SelectList($el);
		}
		this.$ = $el;

		this.isActive = false;

		this.init();
		this.bindEvents();
	}

	SelectList.prototype.init = function() {
		this.mainButton = this.$.find('.main-select-list-option').first();
		this.arrow = this.$.find('.select-list-show-more-picto').first();
		this.list = this.$.find('.select-list-show-more-list').first();
	};

	SelectList.prototype.bindEvents = function() {
		var self = this;
		this.mainButton.click(function(event) {
			event.preventDefault();

			self.toggle();
		});
	};

	SelectList.prototype.active = function() {
		this.mainButton.addClass('active');
		this.list.addClass('showed');
		this.arrow.addClass('up').removeClass('down');
		this.isActive = true;
	};

	SelectList.prototype.unactive = function() {
		this.mainButton.removeClass('active').blur();
		this.list.removeClass('showed');
		this.arrow.removeClass('up').addClass('down');
		this.isActive = false;
	};

	SelectList.prototype.toggle = function() {
		this.isActive ? this.unactive() : this.active();
	};

	SelectList.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return SelectList;
}());