var ModalOpener = (function() {
	'use strict';

	var ModalOpenerCounter = 0;

	function ModalOpener($el) {
		// enforces new
		if (!(this instanceof ModalOpener)) {
			return new ModalOpener($el);
		}
		this.$ = $el;

		this.init();
		this.bindEvents();

		this.identifier = "modal-opener-"+ModalOpenerCounter;

		ModalOpenerCounter++;
	}

	ModalOpener.definedWidth = {
		"ws-modal-login" : 358,
		"ws-modal-inscription" : 958,
		"ws-modal-profil-suppression" : 387,
		"ws-modal-create-liste" : 958
	};

	ModalOpener.prototype.init = function() {
		var self = this;
		this.options = {
			type : 'iframe',
			iframe:{
				scrolling:'none',
				preload:true
			},
			href : this.$.attr('href'),
			padding : 0,
			margin : 0,
			autoHeight : true,
			fitToView : true,
			wrapCSS : 'ws-modal ws-modal-login',
			afterShow: function(){
				var iframe = $('iframe.fancybox-iframe').first();

				iframe.contents().find('.modal-close-button').each(function(index, el) {
					$(el).click(function(event) {
						event.preventDefault();
						self.close();
					});
				});
			}
		};

		var width = ModalOpener.definedWidth[this.$.data('defined-width')];

		if (typeof width === 'number') {
			this.options.width = width;
		}
	};

	ModalOpener.prototype.bindEvents = function() {
		var self = this;

		this.$.click(function(event) {
			event.preventDefault();
			self.open();
		});
	};

	ModalOpener.prototype.unbindEvents = function() {
		var self = this;

		this.$.off();
	};

	ModalOpener.prototype.open = function() {
		$.fancybox.open(this.options);
	};

	ModalOpener.prototype.close = function() {
		$.fancybox.close();
	};

	ModalOpener.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return ModalOpener;
}());