'use strict';

/*RequestAnimationFrame Polyfill*/
(function() {
	var lastTime = 0;
	var vendors = ['ms', 'moz', 'webkit', 'o'];
	for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
		window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
		window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame']
								   || window[vendors[x]+'CancelRequestAnimationFrame'];
	}
	if (!window.requestAnimationFrame)
		window.requestAnimationFrame = function(callback, element) {
			var currTime = new Date().getTime();
			var timeToCall = Math.max(0, 16 - (currTime - lastTime));
			var id = window.setTimeout(function() { callback(currTime + timeToCall); },
			  timeToCall);
			lastTime = currTime + timeToCall;
			return id;
		};
	if (!window.cancelAnimationFrame)
		window.cancelAnimationFrame = function(id) {
			clearTimeout(id);
		};
}());

/*-------------------*/
/*-------------------*/
/*-------------------*/

window.pool = {
	componentNeedingTimeUpdate : [],
	componentNeedingResize : []
};

$(document).ready(function() {
	westoryMain();

	$('.picto.pin').click(function () {
		$(this).blur();
	});
	$( document ).ready( function(){
		// get hush var if existe
		if(window.location.hash) {
			//Puts hash in variable, and removes the # character
			var hash = window.location.hash.substring(1);
			if(hash == "defricheur"){
				$('#defricheur').click();
				console.log('clicked defricheur');
			}
		}
	});
	
});

function westoryMain () {
	var $window = $(window),
		$document = $(document);

	window.westoryApp = {};

	// mise en place des tooltips
	$document.tooltip({
		items:'[data-tooltip]',
		content: function(){
			return $(this).attr('data-tooltip');
		},
		position:{my:"center top+8", at: "center bottom", collision: "flipfit"}
	});

	var time = {};
	time.start = Date.now();
	time.previous = Date.now();
	time.delta = 0;

	var updateTime = function updateTime () {
		currentTime = Date.now();
		time.delta = currentTime - time.previous;
		time.previous = currentTime;
	};

	var resize = {
		both : false,
		width : false,
		height : false,
		every : false,
		size : {
			width : $window.width(),
			height : $window.height()
		}
	};

	var updateResize = function updateResize () {
		var newWidth = $window.width(),
			newHeight = $window.height();

		resize.width = (newWidth != resize.size.width);
		resize.height = (newHeight != resize.size.height);
		resize.every = (resize.width && resize.height);
		resize.both = (resize.width || resize.height);

		resize.size.width = newWidth;
		resize.size.height = newHeight;
	};

	var scroll = {
		change : false,
		toUp : false,
		toDown : false,
		position : 0,
		timeSinceLastChangeToDown : 150000,
		timeSinceLastChangeToUp : 150000,
		timeSinceLastChange : 150000,
		updated : function () {
			updateScroll();
			return scroll;
		}
	};

	var updateScroll = function updateScroll() {
		var newScrollPosition = $document.scrollTop();
		scroll.toDown = (newScrollPosition > scroll.position);
		scroll.toUp = (newScrollPosition < scroll.position);
		scroll.change = (scroll.toUp || scroll.toDown);

		scroll.change ? scroll.timeSinceLastChange = 0 : scroll.timeSinceLastChange += time.delta;
		scroll.toUp ? scroll.timeSinceLastChangeToUp = 0 : scroll.timeSinceLastChangeToUp += time.delta;
		scroll.toDown ? scroll.timeSinceLastChangeToDown = 0 : scroll.timeSinceLastChangeToDown += time.delta;


		if(resize.both){
			scroll.change = true;
		}

		scroll.position = newScrollPosition;
	};

	var period = 0, periodMax = 2500, periodReady = false, updatePeriod = function () {
		if ((periodReady = (period+=time.delta)>periodMax)) {
			period = 0;
		}
	};

	/*-------------------------------------------------------------------------------------*/

	var rightColumn = ContentRightColumn.createComponent($('.content-right-column').first());
	var subMenu = SectionSubMenu.createComponent($('.section-sub-menu').first());
	var topBarInformation = TopBarInformation.createComponent($('.top-bar-information').first());

	var readingInformation = ReadingInformation.createComponent($('.reading-information').first());

	var activityFeed = ActivityFeed.createComponent($('.activity-feed').first());

	var mainMenuSearchBar = MainMenuSearchBar.createComponent($('.main-menu-element-search-bar').first());

	/*------------*/
	var componentMyCommunityFollowUsers = MyCommunityFollowUsers.createComponent($('.my-community-follow-users').first());
	/*------------*/

	window.westoryApp.activityFeed = activityFeed;

	var selectListFicheSerie = SelectList.createComponent($('.fiche-series-content-select-list').first());

	$('.locked-selection').each(function(index, el) {
		LockedSelection.createComponent($(el));
	});

	$('.bubble-modal-wrapper').each(function(index, el) {
		BubbleModalWrapper.createComponent($(el));
	});

	$('.slider').each(function(index, el) {
		Slider.createComponent($(el));
	});

	$('.purchase').each(function(index, el) {
		Purchase.createComponent($(el));
	});

	$('.modal-opener').each(function(index, el) {
		ModalOpener.createComponent($(el));
	});

	BackgroundPub.createComponent($('.background-pub').first());

	var landingPage = LandingPage.createComponent($('.landing-page-content').first());

	var listFormCreation = WSFormListCreation.createComponent($('.ws-form-list-creation').first());

	var listUpdater = WSListUpdate.createComponent($('#ws-list-update'));

	/*-------------------------------------------------------------------------------------*/

	window.pool.componentNeedingResize.push(rightColumn);
	window.pool.componentNeedingResize.push(subMenu);
	window.pool.componentNeedingResize.push(landingPage);

	/*-------------------------------------------------------------------------------------*/

	$('.open-activity-feed-button').click(function(event) {
		event.preventDefault();
		activityFeed.toggle();
	});
	
	var simpleFrameEvent = function simpleFrameEvent() {
		var componentNeedingResize = window.pool.componentNeedingResize;
		for(var i = 0,imax = componentNeedingResize.length;i<imax;i++){
			var component = componentNeedingResize[i];

			if (component) {
				component.onResize(resize)
			};
		}

		var componentNeedingTimeUpdate = window.pool.componentNeedingTimeUpdate;
		for(var i = 0,imax = componentNeedingTimeUpdate.length;i<imax;i++){
			var component = componentNeedingTimeUpdate[i];

			if (component) {
				component.onTimeUpdate(time);
			}
		}

		if (periodReady) {
			if (rightColumn) {
				rightColumn.updatePageContentHeight();
			}
			if (subMenu) {
				subMenu.updatePageContentHeight();
			}
		}
	};

	requestAnimationFrame(function requestAnimationFrameEvent() {
		updateTime();
		updateResize();
		updateScroll();
		updatePeriod();

		simpleFrameEvent();

		requestAnimationFrame(requestAnimationFrameEvent);
	});

	updateTime();
	updateResize();
	updateScroll();
	simpleFrameEvent();
}