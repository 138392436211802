var ContentRightColumn = (function() {
	'use strict';

	function ContentRightColumn($el) {
		// enforces new
		if (!(this instanceof ContentRightColumn)) {
			return new ContentRightColumn($el);
		}

		this.$ = $el;

		this.parentContent = this.$.parents('.content').first();
		this.fakeInnerBlock = this.$.find('.fake-inner-block').first();

		this.init();
	}

	ContentRightColumn.prototype.init = function() {
		this.updatePageContentHeight({repeat : true});
	};

	ContentRightColumn.prototype.onResize = function(resize) {
		if (resize.width) {
			this.updatePageContentHeight();
		}
	};

	ContentRightColumn.prototype.updatePageContentHeight = function(params) {
		var params = typeof params === 'object' ? params : {repeat : false};

		var thisHeight = this.getHeight(), parentHeight = this.parentContentHeight();

		if(thisHeight !== parentHeight){
			this.parentContent.css('min-height', thisHeight+'px');
		}

		this.fakeInnerBlock.height(parentHeight > thisHeight ? parentHeight : thisHeight);

		if(params.repeat === true){
			this.updatePageContentHeight();
		}
	};

	ContentRightColumn.prototype.getHeight = function() {
		return this.$.height();
	};

	ContentRightColumn.prototype.parentContentHeight = function() {
		return this.parentContent.height()+Config.css.sectionListing.paddingBottom();
	};

	ContentRightColumn.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return ContentRightColumn;
}());