var MyCommunityFollowUsers = (function() {
	'use strict';

	var componentCount = 0;

	function MyCommunityFollowUsers($el) {
		// enforces new
		if (!(this instanceof MyCommunityFollowUsers)) {
			return new MyCommunityFollowUsers($el);
		}

		this.componentIdentifier = 'componentMyCommunityFollowUsers'+(componentCount++);

		this.$ = $el;

		this.$.addClass(this.componentIdentifier);

		this.searchedValue = '';
		this.sortValue = '';

		this.init();
	}

	MyCommunityFollowUsers.prototype.init = function() {
		var self = this;
		var $body = $('body');

		this.initSearchBar();

		$body.on('submit', '.'+this.componentIdentifier+' form', function (event) {
			self.submitEvent(event);
		});

		$body.on('change', '.'+this.componentIdentifier+' select', function (event) {
			self.selectChangeEvent(event, $(this));
		});

		$body.on('click', '.'+this.componentIdentifier+' .search-bar-result', function(evt){
			self.serieClickEvent(this);
			self.searchBar.closeResultPlaceholder();
			self.searchBar.emptySearch();
		});
	};

	MyCommunityFollowUsers.prototype.initSearchBar = function() {
		this.searchBar = SearchBar.createComponent(this.$.find('.search-bar').first());
	};

	MyCommunityFollowUsers.prototype.submitEvent = function(event) {
		event.preventDefault();
		this.search(this.searchBar.getSearchedValue());
	};

	MyCommunityFollowUsers.prototype.selectChangeEvent = function(event, target) {
		event.preventDefault();
		this.sort(target.val());
	};

	MyCommunityFollowUsers.prototype.serieClickEvent = function(serie) {
		this.search($(serie).data('serie-id'));
	};

	MyCommunityFollowUsers.prototype.search = function(search) {
		var self = this;
		var searchedValue = typeof search === 'string' ? search : this.searchedValue;
		this.searchedValue = searchedValue;

		this.tryReload();
	};

	MyCommunityFollowUsers.prototype.sort = function(sort) {
		var self = this;
		var sortValue = typeof sort === 'string' ? sort : this.sortValue;
		this.sortValue = sortValue;

		this.tryReload();
	};

	MyCommunityFollowUsers.prototype.tryReload = function() {
		if (this.searchedValue.length > 0) {
			this.reload();
		}
	};

	MyCommunityFollowUsers.prototype.reload = function() {
		window.reloadableBlockManager.reload('westory_content_communaute_followusersearchresult', {
			search: this.searchedValue,
			sort: this.sortValue
		});
	};

	MyCommunityFollowUsers.createComponent = function createComponent (jqueryElement) {
		var component = null;
		if(jqueryElement.size() > 0){
			component = new this(jqueryElement);
		}

		return component;
	};

	return MyCommunityFollowUsers;
}());

