var FicheSerieCommunauteAction = (function() {
    'use strict';

    function FicheSerieCommunauteAction($el) {
        // enforces new
        if (!(this instanceof FicheSerieCommunauteAction)) {
            return new FicheSerieCommunauteAction($el);
        }

        this.$ = $el;

        this.init();
    }

    FicheSerieCommunauteAction.prototype.init = function() {
        /* this.commentsShowed = false;
        this.replyShowed = false;

        this.commentsList = this.$.find('.toolbar-action-comment-list');
        this.comments = this.$.find('.feed-action-comments');

        this.seeCommentsPicto = this.$.find('.activity-feed-action-tool-bar-element.see-comments');
        this.closeCommentsButton = this.$.find('.feed-action-comments-action-bar-close');

        this.replyButton = this.$.find('.feed-action-comments-action-bar-reply');
        this.commentReply = this.$.find('.feed-action-comments-reply');

        this.commentForm = this.commentReply.find('form').first(); */

        this.bindEvents();
    };

    FicheSerieCommunauteAction.prototype.bindEvents = function() {
        var self = this;

        /* this.seeCommentsPicto.click(function(event) {
            event.preventDefault();
            self.toggleComments();
        });

        this.closeCommentsButton.click(function(event) {
            event.preventDefault();
            self.hideComments();
        });

        this.replyButton.click(function(event) {
            event.preventDefault();
            self.toggleReply();
        });

        this.commentForm.on('submit', function (event) {
            event.preventDefault();
            self.submitCommentForm();
        });*/
    };

    FicheSerieCommunauteAction.prototype.unbindEvents = function() {
        /* this.seeCommentsPicto.unbind('click');
        this.closeCommentsButton.unbind('click');
        this.replyButton.unbind('click');
        this.commentForm.unbind('submit'); */
    };

    FicheSerieCommunauteAction.prototype.getFormValue = function(name) {
        return this.commentForm.find('[name="'+name+'"]').first().val();
    };

    FicheSerieCommunauteAction.prototype.submitCommentForm = function() {
        this.postComment({
            text: this.getReplyValue(),
            actionId: this.getFormValue('actionId'),
            actionClass: this.getFormValue('actionClass')
        });
    };

    FicheSerieCommunauteAction.prototype.addComment = function(comment) {
        this.commentsList.append(componentFactory.blockComment(comment));
    };

    FicheSerieCommunauteAction.prototype.postComment = function(commentData) {
        var self = this;
        $.ajax({
            type: "POST",
            url: Routing.generate('westory_message_message_postmessage', {}),
            data: commentData,
            success: function (data) {
                if (data.success) {
                    self.addComment(data.comment)
                }
            },
            dataType: 'json'
        });
    };

    return FicheSerieCommunauteAction;
}());