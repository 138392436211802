var ActivityFeedAction = (function() {
	'use strict';

	function ActivityFeedAction($el) {
		// enforces new
		if (!(this instanceof ActivityFeedAction)) {
			return new ActivityFeedAction($el);
		}

		this.$ = $el;

		this.init();
	}

	ActivityFeedAction.prototype.init = function() {
		this.commentsShowed = false;
		this.replyShowed = false;

		this.commentsList = this.$.find('.toolbar-action-comment-list');
		this.comments = this.$.find('.feed-action-comments');

		this.seeCommentsPicto = this.$.find('.activity-feed-action-tool-bar-element.see-comments');
		this.closeCommentsButton = this.$.find('.feed-action-comments-action-bar-close');

		this.replyButton = this.$.find('.feed-action-comments-action-bar-reply');
		this.commentReply = this.$.find('.feed-action-comments-reply');

		this.commentForm = this.commentReply.find('form').first();

		this.bindEvents();
	};

	ActivityFeedAction.prototype.bindEvents = function() {
		var self = this;

		this.seeCommentsPicto.click(function(event) {
			event.preventDefault();
			self.toggleComments();
		});

		this.closeCommentsButton.click(function(event) {
			event.preventDefault();
			self.hideComments();
		});

		this.replyButton.click(function(event) {
			event.preventDefault();
			self.toggleReply();
		});

		this.commentForm.on('submit', function (event) {
			event.preventDefault();
			self.submitCommentForm();
		});
	};

	ActivityFeedAction.prototype.unbindEvents = function() {
		this.seeCommentsPicto.unbind('click');
		this.closeCommentsButton.unbind('click');
		this.replyButton.unbind('click');
		this.commentForm.unbind('submit');
	};

	ActivityFeedAction.prototype.getComments = function() {
		return this.commentsList.find('>.block-comment');
	};

	ActivityFeedAction.prototype.toggleComments = function() {
		this.commentsShowed ? this.hideComments() : this.showComments();

		return this.getComments().size() ? null : this.showReply();
	};

	ActivityFeedAction.prototype.hideComments = function() {
		this.$.removeClass('comments-showed');
		this.seeCommentsPicto.removeClass('active').blur();
		this.comments.removeClass('showed');
		this.commentsShowed = false;
	};

	ActivityFeedAction.prototype.showComments = function() {
		this.$.addClass('comments-showed');
		this.seeCommentsPicto.addClass('active');
		this.comments.addClass('showed');
		this.commentsShowed = true;
	};

	ActivityFeedAction.prototype.toggleReply = function() {
		this.replyShowed ? this.hideReply() : this.showReply();
	};

	ActivityFeedAction.prototype.showReply = function() {
		this.$.addClass('reply-showed');
		this.replyButton.addClass('active').blur();
		this.commentReply.addClass('showed');
		this.replyShowed = true;
	};

	ActivityFeedAction.prototype.hideReply = function() {
		this.$.removeClass('reply-showed');
		this.replyButton.removeClass('active').blur();
		this.commentReply.removeClass('showed');
		this.replyShowed = false;
	};

	ActivityFeedAction.prototype.getReplyValue = function() {
		return this.commentForm.find('textarea').first().val();
	};

	ActivityFeedAction.prototype.getFormValue = function(name) {
		return this.commentForm.find('[name="'+name+'"]').first().val();
	};

	ActivityFeedAction.prototype.submitCommentForm = function() {
		this.postComment({
			text: this.getReplyValue(),
			actionId: this.getFormValue('actionId'),
			actionClass: this.getFormValue('actionClass')
		});
	};

	ActivityFeedAction.prototype.addComment = function(comment) {
		this.commentsList.append(componentFactory.blockComment(comment));
	};

	ActivityFeedAction.prototype.postComment = function(commentData) {
		var self = this;
		$.ajax({
			type: "POST",
			url: Routing.generate('westory_message_message_postmessage', {}),
			data: commentData,
			success: function (data) {
				if (data.success) {
					self.addComment(data.comment)
				}
			},
			dataType: 'json'
		});
	};

	return ActivityFeedAction;
}());